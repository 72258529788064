import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import TopBanner from '../components/TopBanner'
import SoundCloud from '../components/Soundcloud'
import Instagram from '../components/Instagram'
import RA from '../components/RA'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import ClientIcons from '../components/ClientIcons'

const Title = styled.h2`
  display: flex;
  font-size: calc(24px + 2vw);
  letter-spacing: 2vw;
  color: #efe5d2;
  position: relative;
  border-radius: 3px 0 0 3px;
  align-items: center;
  font-weight: 400;
  flex: 3;
  padding: 20px 0;
  justify-content: center;
`

const ClientsTitle = styled.h2`
  display: flex;
  border-top: 1px solid #affdd3;
  font-size: calc(24px + 2vw);
  letter-spacing: 2vw;
  color: #affdd3;
  position: relative;
  border-radius: 3px 0 0 3px;
  align-items: center;
  font-weight: 300;
  text-transform: uppercase;
  background: black;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  padding-top: 40px;
`

const SocialTitle = styled.h3`
  display: flex;
  font-size: calc(16px + 2vw);
  color: pink;
  writing-mode: vertical-rl;
  font-weight: 400;
  margin-bottom: 25px;
`

const ImageContainer = styled.section`
  max-height: 70vh;
  content: "";
  width: 100%;
  max-width: 95vh;
  position: relative;
  flex: 2;
`

const StyledSection = styled.section`
  postion: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const StyledBio = styled.p`
  margin-top: 1vh;
  padding: 20px 10px;
  font-size: calc(11px + 0.75vw);
  line-height: calc(14px + 0.75vw);
  color: #efe5d2;
  @media (max-width: 630px) {
    margin-top: 20px;
    padding: 0 20px;
  }
`

const BioSection = styled.section`
  padding: 0 1vw 5vw 1vw;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 630px) {
    flex-direction: column;
  }
`

const StyledSocialContainer = styled.section`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  z-index: 2;
`

const StyledSocialLink = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 25px 0;
  svg {
    fill: rgb(238, 239, 230) ;
    &:hover {
      fill: #affdd3;
    }
  }
  @media (max-width: 720px) { margin: 0; }
  @media (max-width: 580px) {
    svg {
      height: 36px;
      width: 36px;
    }
  }
`
const StyledSocialLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  flex: 1;
  max-height: 56vh;
  position: relative;
  @media (max-width: 720px) {
    position: absolute;
    right: 15px;
    max-height: 70vw;
  }
`

export const BioPageTemplate = ({ title, bio, picture }) => {
  return (
    <Layout hasPadding style={{ height: 'auto' }}>
      <TopBanner title="about" />
      <Title>{title}</Title>
      <StyledSection>
        <ImageContainer>
          <PreviewCompatibleImage
            imageInfo={{
              backgroundColor: '#FB3B59',
              image: picture,
              alt: 'bio-picture',
            }}
          />
        </ImageContainer>
        <StyledSocialLinkContainer>
          <StyledSocialLink>
            <a target="_blank" href="https://www.instagram.com/elledeenyc/?hl=en">
              <Instagram />
            </a>
          </StyledSocialLink>
          <StyledSocialLink>
            <a target="_blank" href="https://soundcloud.com/djelledee">
              <SoundCloud />
            </a>
          </StyledSocialLink>
          <StyledSocialLink>
            <a target="_blank" href="https://www.residentadvisor.net/dj/elledee">
              <RA />
            </a>
          </StyledSocialLink>
        </StyledSocialLinkContainer>
      </StyledSection>
      <BioSection>
        {bio.split(/\s{4}/).map((bioSecion, idx) =>
          <StyledBio key={idx}>{bioSecion}</StyledBio>)}
      </BioSection>
      <ClientsTitle>Clients Include</ClientsTitle>
      <ClientIcons />
    </Layout>
  )
}

BioPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  picture: PropTypes.object.isRequired,
}

const BioPage = ({ data }) => {
  const { markdownRemark: post, file: picture } = data
  return (
    <BioPageTemplate
      title={post.frontmatter.title}
      bio={post.frontmatter.bio}
      picture={picture}
    />
  )
}

BioPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BioPage

export const bioPageQuery = graphql`
  query BioPage($id: String!) {
    file(relativePath: {
      regex: "/bio.jpg/"
    }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        bio
      }
    }
  }
`
