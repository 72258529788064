import React from 'react'

const SoundCloud = () => (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width="48px" height="48px" viewBox="0 0 97.75 97.75"
  >
    <g>
      <path d="M48.875,0C21.883,0,0,21.882,0,48.875S21.883,97.75,48.875,97.75S97.75,75.868,97.75,48.875S75.867,0,48.875,0z
        M7.739,62.171C7.712,62.364,7.573,62.5,7.399,62.5c-0.175,0-0.315-0.136-0.339-0.331l-0.621-4.633l0.621-4.713
        c0.023-0.196,0.164-0.333,0.339-0.333c0.174,0,0.313,0.136,0.34,0.33l0.736,4.717L7.739,62.171z M10.893,64.991
        c-0.03,0.2-0.176,0.342-0.354,0.342c-0.18,0-0.328-0.144-0.353-0.343l-0.834-7.454l0.834-7.622
        c0.024-0.199,0.173-0.344,0.353-0.344c0.179,0,0.324,0.141,0.354,0.344l0.948,7.622L10.893,64.991z M14.298,66.252
        c-0.026,0.241-0.205,0.415-0.426,0.415c-0.224,0-0.402-0.174-0.425-0.417l-0.792-8.712c0,0,0.792-9.043,0.792-9.044
        c0.022-0.241,0.201-0.416,0.425-0.416c0.221,0,0.399,0.175,0.426,0.416l0.899,9.044L14.298,66.252z M17.732,66.529
        c-0.024,0.277-0.238,0.488-0.497,0.488c-0.264,0-0.479-0.211-0.5-0.488l-0.748-8.989l0.748-9.292c0.021-0.28,0.236-0.49,0.5-0.49
        c0.259,0,0.473,0.21,0.497,0.487l0.85,9.294L17.732,66.529z M21.193,66.604c-0.021,0.318-0.268,0.562-0.57,0.562
        c-0.305,0-0.551-0.243-0.571-0.562l-0.706-9.063l0.706-8.619c0.021-0.321,0.267-0.563,0.571-0.563c0.303,0,0.549,0.242,0.57,0.56
        l0.801,8.623L21.193,66.604z M24.682,66.607v-0.004c-0.021,0.355-0.302,0.636-0.643,0.636c-0.344,0-0.625-0.28-0.644-0.634
        l-0.661-9.062l0.661-14.024c0.019-0.357,0.3-0.636,0.644-0.636c0.341,0,0.622,0.279,0.643,0.635l0.75,14.025L24.682,66.607z
        M28.145,66.6v-0.005c-0.018,0.398-0.333,0.708-0.716,0.708c-0.384,0-0.698-0.31-0.713-0.705l-0.622-9.007
        c0,0,0.619-17.229,0.619-17.23c0.018-0.397,0.332-0.708,0.716-0.708c0.383,0,0.698,0.311,0.716,0.708l0.701,17.23L28.145,66.6z
        M31.74,66.462v-0.005c-0.018,0.438-0.362,0.779-0.786,0.779c-0.427,0-0.773-0.342-0.788-0.775l-0.577-8.914
        c0,0,0.577-18.667,0.577-18.669c0.015-0.438,0.361-0.781,0.788-0.781c0.424,0,0.769,0.343,0.786,0.781l0.652,18.669L31.74,66.462z
        M35.311,66.402c-0.014,0.478-0.393,0.853-0.859,0.853c-0.47,0-0.846-0.375-0.858-0.849l-0.536-8.858l0.534-19.297
        c0.015-0.479,0.391-0.855,0.86-0.855c0.467,0,0.846,0.375,0.859,0.855l0.603,19.298L35.311,66.402z M38.908,66.325v-0.007
        c-0.014,0.519-0.423,0.929-0.932,0.929c-0.511,0-0.92-0.41-0.931-0.924l-0.493-8.773l0.49-18.805
        c0.014-0.52,0.423-0.927,0.934-0.927c0.509,0,0.918,0.406,0.932,0.925l0.555,18.807L38.908,66.325z M42.534,66.27v-0.006
        c-0.011,0.561-0.453,1-1.005,1c-0.551,0-0.994-0.439-1.005-0.994l-0.449-8.719l0.449-18.119c0.011-0.561,0.454-1,1.005-1
        c0.552,0,0.994,0.44,1.005,1l0.503,18.121L42.534,66.27z M46.232,65.354l-0.045,0.854c-0.005,0.295-0.13,0.563-0.324,0.758
        c-0.195,0.193-0.461,0.314-0.753,0.314c-0.329,0-0.625-0.152-0.824-0.39c-0.146-0.176-0.239-0.399-0.25-0.641
        c-0.002-0.012-0.003-0.023-0.003-0.037c0,0-0.408-8.654-0.408-8.667l0.404-21.352l0.004-0.203c0.005-0.377,0.202-0.708,0.497-0.899
        c0.167-0.11,0.365-0.175,0.58-0.175c0.218,0,0.424,0.068,0.595,0.184c0.285,0.193,0.476,0.519,0.482,0.889l0.452,21.562
        L46.232,65.354z M49.825,66.092V66.09v-0.008c-0.013,0.631-0.525,1.146-1.15,1.146c-0.627,0-1.143-0.515-1.151-1.138l-0.232-4.209
        l-0.238-4.325l0.469-23.397l0.002-0.118c0.004-0.356,0.17-0.674,0.427-0.885c0.197-0.164,0.45-0.262,0.725-0.262
        c0.214,0,0.414,0.061,0.585,0.165c0.33,0.201,0.559,0.565,0.565,0.98l0.509,23.518L49.825,66.092z M80.872,67.25
        c0,0-28.909,0.003-28.937,0c-0.625-0.062-1.121-0.559-1.127-1.198c0,0,0-33.131,0-33.132c0.006-0.609,0.215-0.922,1.004-1.227
        c2.025-0.785,4.322-1.248,6.676-1.248c9.627,0,17.515,7.38,18.347,16.787c1.242-0.52,2.606-0.81,4.037-0.81
        c5.767,0,10.438,4.674,10.438,10.439C91.311,62.626,86.639,67.25,80.872,67.25z"/>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
)

export default SoundCloud
